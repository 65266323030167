import Vue from 'vue';
import MandantService from './mandant.service';
import JwtService from '@/common/jwt.service';
import ApiService from '@/common/api.service';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';
import { ADRESSEN_TYP } from '@/constants';

export const getDefaultSort = () => ({ currentSort: 'bezeichnung', currentSortDir: 'asc' });

const getDefaultItem = () => ({
    id: null,
    bezeichnung: null,
    uid: null,
    email: null,
    kleinunternehmerDEText: null,
    kleinunternehmerENText: null,
    patternArtikelnummer: null,
    patternKundennummer: null,
    firstArtikelNummer: null,
    firstKundenNummer: null,
    exportDEText: null,
    exportENText: null,
    innergemeinschaftlichDEText: null,
    innergemeinschaftlichENText: null,
    adresseLists: [
        {
            plz: null,
            ort: null,
            strasse: null,
            hausNummer: null,
            land: null,
        },
    ],
    rechnungsNummerProJahr: true,
    bankomat: false,
    lieferKontaktAusklappen: false,
    belegeFinalisieren: false,
    crmAusklappen: false,
    kassaBetrag: null,
    kassaStartdatum: null,
    kassaStartZeroYear: null,
    vacationMode: null,
    zeitausgleich: null,
    zeiterfassungRestrictions: null,
    uidData: {
        uid_uid: null,
        uid_user: null,
        uid_password: null,
        uid_tid: null,
    },
    hasNewBelege: false,
    confirmTimes: false,
    industrySector: null,
    aiActivated: false,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    itemModules: [],
    list: [],
    selectedMandant: {
        id: null,
        bezeichnung: null,
        userLists: [],
    },
    selectedModules: [],
    mandanten: [],
});

const mandant = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
            Vue.set(state, 'itemModules', Array.isArray(data.modulList) ? data.modulList : []);
        },
        updateMandanten(state, data) {
            Vue.set(state, 'mandanten', [...data]);
        },
        updateSelected(state, data) {
            Vue.set(state, 'selectedMandant', data);
            Vue.set(state, 'selectedModules', Array.isArray(data.modulList) ? data.modulList : []);
        },
        updateSelectedModules(state, data) {
            Vue.set(state, 'selectedModules', [...data]);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async loadAll(context, params) {
            const mandant = await MandantService.query({ params });
            context.commit('updateAll', mandant);
        },
        async selectMandant(context, selectedMandant = null) {
            localStorage.removeItem('last_selected_kunde');
            JwtService.saveMandant(selectedMandant.id || null);
            ApiService.setMandantHeader(selectedMandant.id || null);
            context.commit('updateSelected', selectedMandant);
            const modules = await MandantService.getModules();
            context.commit('updateSelectedModules', modules.data);
        },
        async loadAllandUpdateSelected(context, selectedMandant = null) {
            //TODO: Es werden nur 100 Mandanten geladen (sollte bei Gelegenheit mal geändert werden)
            const { data } = await MandantService.query({ params: { size: 100, sort: 'bezeichnung,asc' } });
            context.commit('updateMandanten', data);

            let mandant2set = null;

            if (Array.isArray(data)) {
                if (selectedMandant != null && selectedMandant.id != null) {
                    mandant2set = data.find((x) => x.id == selectedMandant.id);
                }
                if (mandant2set != null) {
                    if (this.state.selectedMandant != null && this.state.selectedMandant.id != null) {
                        mandant2set = data.find((x) => x.id == this.state.selectedMandant.id);
                    }
                }
                if (mandant2set == null) {
                    mandant2set = data[0];
                }
            } else {
                mandant2set = getDefaultState().selectedMandant;
            }

            context.dispatch('selectMandant', mandant2set);
            MandantService.get(mandant2set.id);
        },
        async createStartBeleg(context, mandant) {
            await MandantService.createStartBeleg(mandant);
        },
        async getDep(context, date) {
            const { data } = await MandantService.getDep(date);
            context.commit('update', data);
            return data;
        },
        async getSJournal(context) {
            const { data } = await MandantService.getSJournal();
            context.commit('update', data);
            return data;
        },
        async create(context, client_data) {
            const { data } = await MandantService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await MandantService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await MandantService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { headers, data } = await MandantService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await MandantService.delete(slug);
        },
        async deactivate(context, slug) {
            const { data } = await MandantService.deactivate(slug);
            context.commit('updateList', data);
            return data;
        },
        async activate(context, slug) {
            const { data } = await MandantService.activate(slug);
            context.commit('updateList', data);
            return data;
        },
    },
    getters: {
        data(state) {
            return state.item;
        },
        data_rechnungsadresse(state) {
            let rechnungsadresse = null;
            if (state.item != null && Array.isArray(state.item.adresseLists) && state.item.adresseLists.length > 0) {
                for (var i = 0; i < state.item.adresseLists.length; i++) {
                    if (state.item.adresseLists[i].adressenTyp === ADRESSEN_TYP.RECHNUNGSADRESSE || state.item.adresseLists[i].adressenTyp == null) {
                        rechnungsadresse = state.item.adresseLists[i];
                        break;
                    }
                }
            }
            return rechnungsadresse;
        },
        dataHasModuleRKSV(state) {
            if (Array.isArray(state.itemModules)) {
                if (state.itemModules.includes('rksv')) return true;
            }
            return false;
        },
        dataHasModuleKassabuch(state) {
            if (Array.isArray(state.itemModules)) {
                if (state.itemModules.includes('kassabuch')) return true;
            }
            return false;
        },
        selectedHasModuleLagerstand(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('lagerstand')) return true;
            }
            return false;
        },
        selectedHasModuleLagerplatz(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('lagerplatz')) return true;
            }
            return false;
        },
        selectedHasModuleRKSV(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('rksv')) return true;
            }
            return false;
        },
        selectedHasModuleZahlung(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('zahlung')) return true;
            }
            return false;
        },
        selectedHasModuleAuswertung(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('auswertung')) return true;
            }
            return false;
        },
        selectedHasModuleZeiterfassung(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('zeiterfassung')) return true;
            }
            return false;
        },
        selectedHasModuleKassabuch(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('kassabuch')) return true;
            }
            return false;
        },
        selectedHasModuleProject(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('project')) return true;
            }
            return false;
        },
        selectedHasModuleBestellung(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('bestellung')) return true;
            }
            return false;
        },
        selectedHasModuleRechnungslegung(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('rechnungslegung')) return true;
            }
            return false;
        },
        selectedHasModuleVerleih(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('verleih')) return true;
            }
            return false;
        },
        selectedHasModuleContent(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('content')) return true;
            }
            return false;
        },
        selectedHasModuleMailVersand(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('mailversand')) return true;
            }
            return false;
        },
        selectedHasModuleBenachrichtigungen(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('benachrichtigungen')) return true;
            }
            return false;
        },
        selectedHasModuleFahrzeuge(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('fahrzeuge')) return true;
            }
            return false;
        },
        selectedHasModuleAssetKunde(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('assetkunde')) return true;
            }
            return false;
        },
        selectedHasModuleAssetArtikel(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('assetartikel')) return true;
            }
            return false;
        },
        selectedHasModuleArtikelkatalog(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('artikelkatalog')) return true;
            }
            return false;
        },
        selectedHasModuleArtikelbilder(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('artikelbilder')) return true;
            }
            return false;
        },
        selectedHasModuleWebshop(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('webshop')) return true;
            }
            return false;
        },
        selectedhasModulePreisKalkulator(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('preiskalkulator')) return true;
            }
            return false;
        },
        selectedhasModuleUidCheck(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('uidcheck')) return true;
            }
            return false;
        },
        selectedhasModuleCrm(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('crm')) return true;
            }
            return false;
        },
        selectedHasModuleQuartalskalender(state) {
            if (Array.isArray(state.selectedModules)) {
                if (state.selectedModules.includes('quartalskalender')) return true;
            }
            return false;
        },
        selectedUsers(state) {
            return state.selectedMandant.userLists;
        },
        mandanten(state) {
            return state.mandanten;
        },
        selectedMandant(state) {
            return state.selectedMandant;
        },
    },
};

export default _.merge(mandant, abstractStore.getDefaultStore());
